import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/CloseRounded';
import cx from 'classnames';
import { Button } from '@material-ui/core';
import { useCookies } from 'react-cookie';

/**
 * CookieBanner
 *
 * Banner for user to address cookie policy
 */
const CookieBanner = () => {
  const [cookies, setCookie] = useCookies(['acceptCookieAgreement']);
  const hasBeenAddressed = () => cookies.acceptCookieAgreement !== undefined;
  const [noticeAddressed, setNoticeAddressed] = useState(hasBeenAddressed);

  // Interaction with the banner
  const handleNotificationInteraction = (agree) => {
    setCookie('acceptCookieAgreement', agree, {
      maxAge: 30 * 24 * 60 * 60, // 30 days
      path: '/',
    });
    setNoticeAddressed(true);
  };

  useEffect(() => {
    setNoticeAddressed(hasBeenAddressed());
  });

  return (
    <div
      className={cx('cookie-banner', {
        active: !noticeAddressed,
      })}
    >
      <div className='inner-wrapper'>
        <div className='left-col'>
          <p>{'This website uses cookies. By continuing to browse/log in to this website you are agreeing to this use of cookies.'}</p>
        </div>
        <div className='right-col'>
          <div className='content-wrapper'>
            <div>
              <Button
                onClick={() => handleNotificationInteraction(true)}
                variant='contained'
                color='secondary'
                className='action-btn'
                id='cookie-agree'
              >
                {'I agree'}
                <span className='sr-only'>{'to the cookie terms'}</span>
              </Button>
            </div>
            <div>
              <Button
                href='https://myjewelryrepair.com/privacy/'
                className='action-btn'
                vartiant='text'
                id='cookie-learn-more'
              >
                {'Learn more'}
                <span className='sr-only'>{'about storing cookie terms'}</span>
              </Button>
            </div>
            <div>
              <Button
                onClick={() => handleNotificationInteraction(false)}
                className='close-btn'
              >
                <CloseIcon />
                <span className='sr-only'>{'Close and deny cookie terms'}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
