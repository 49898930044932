import 'assets/scss/main.scss';

import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorMessage from 'components/Global/ErrorMessage';
import Head from 'next/head';
import { init } from '@sentry/node';
import { ErrorBoundary } from '@sentry/react';
import { RewriteFrames } from '@sentry/integrations';
import Layout from 'components/Global/Layout';
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'utils/theme';
import { UserProvider } from '@auth0/nextjs-auth0';
import * as gtag from 'utils/gtag';
import HeadConfig from 'components/Global/HeadConfig';
import TagManager from 'react-gtm-module';
import { CartProvider } from 'contexts/CartContext';
import { OrderProvider } from 'contexts/OrderContext';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const integrations = [];
  if (
    process.env.NEXT_IS_SERVER === 'true'
    && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
  ) {
    // For Node.js, rewrite Error.stack to use relative paths, so that source
    // maps starting with ~/_next map to files in Error.stack with path
    // app:///_next
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(
            process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR,
            'app:///',
          );
          frame.filename = frame.filename.replace('.next', '_next');
          return frame;
        },
      }),
    );
  }

  init({
    enabled: process.env.NODE_ENV === 'production',
    integrations,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    beforeSend: (event) => {
      // check if exception message contains specific text
      if (event.exception) {
        if (event.exception.values[0].type.includes('SyntaxError')) {
          return null;
        }
        return event;
      }
    },
  });
}

/**
 * App
 * Page/Component
 *
 * @param {*} props
 *
 * Contains application setup
 */
const MyApp = (props) => {
  const { Component, pageProps } = props;

  // google analytics setup
  const router = useRouter();
  useEffect(() => {
    TagManager.initialize({ gtmId: `${process.env.NEXT_PUBLIC_GA_TRACKING_ID}` });
    const handleRouteChange = (url) => {
      gtag.GTMPageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ErrorBoundary fallback={ErrorMessage}>
        <UserProvider>
          <CartProvider>
            <OrderProvider>
              <Layout>
                <Head>
                  <meta name='viewport' content='initial-scale=1.0, width=device-width' key='viewport' />
                </Head>
                <HeadConfig />
                <ErrorBoundary fallback={ErrorMessage}>
                  <Component {...pageProps} />
                </ErrorBoundary>
              </Layout>
            </OrderProvider>
          </CartProvider>
        </UserProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default MyApp;
