import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import * as site from 'utils/getSite';
// import { esES } from '@material-ui/core/locale';

// resource: https://material-ui.com/customization/theming/

// Create a theme instance
const breakpointValues = {
  xs: 0,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1620,
  xxl: 2000,
};

const isMacys = site.getSite.includes('macys');

const colors = isMacys
  ? {
    primary: '#000',
    secondary: '#EA0000',
    tertiary: '#6F6F6F',
    offBlack: '#111111',
    greyHeader: '#848484',
  }
  : {
    primary: '#000',
    secondary: '#B1904B',
    tertiary: '#6F6F6F',
    offBlack: '#111111',
    greyHeader: '#848484',
  };

const theme = createTheme({
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: '40px',
        paddingRight: '40px',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '0',
      },
    },
    MuiCardContent: {
      root: {
        padding: '40px',
        '&:last-child': {
          paddingBottom: '40px',
        },
      },
    },
    MuiButton: {
      root: {
        fontWeight: '900',
        borderRadius: '0',
        height: '60px',
        textTransform: 'uppercase',
        fontSize: '14px',
        boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      },
      text: {
        paddingLeft: '15px',
        paddingRight: '15px',
        boxShadow: 'none',
      },
    },
    MuiFormLabel: {
      root: {
        margin: '0 0 10px 0',
      },
    },
    MuiTextField: {
      root: {
        marginTop: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        margin: '0 0 20px 0',
        minHeight: 60,
      },
      notchedOutline: {
        borderColor: colors.offBlack,
      },
    },
  },
  typography: {
    fontFamily: ['Lato', 'Arial', 'Helvetica Neue', 'sans-serif'].join(','),
    fontSize: 16,
    h1: {
      fontSize: '1.875em', // 30px
      fontWeight: '700',
      marginBottom: 15,
    },
    h2: {
      fontSize: '1.625em', // 26px
      fontWeight: '700',
    },
    h3: {
      fontSize: '1.188em', // 19px
      fontWeight: '700',
      marginBottom: '1rem',
    },
    h4: {
      fontSize: '1em', // 16px
      fontWeight: '700',
      marginBottom: '1rem',
    },
    h5: {
      fontSize: '0.875em', // 14px
      fontWeight: '700',
      marginBottom: '1rem',
    },
    h6: {
      fontSize: '.75em', // 12px
      fontWeight: '700',
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1em', // 16px
      color: colors.tertiary,
      marginBottom: '1rem',
    },
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    up: (key) => `@media (min-width:${breakpointValues[key]}px)`,
    down: (key) => `@media (max-width:${breakpointValues[key]}px)`,
  },
  spacing: 10,
});

export default theme;

export { breakpointValues };
