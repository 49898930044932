import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

/**
 * HeadConfig
 * Component
 *
 * Creates a page title based on router pathname matched against object
 */
const HeadConfig = () => {
  const routeName = useRouter().pathname;
  const sitename = 'My Jewelry Repair';
  const routeTitles = {
    '/my-account': 'My Account',
    '/my-orders': 'My Orders',
    '/my-orders/[orderId]': 'My Orders',
    '/create-order': 'Create Order',
    '/order-summary': 'Order Summary',
    '/order-confirmation': 'Order Confirmation',
    '/create-account': 'Create Account',
    '/no-printer-access': 'No Printer Access',
    '/_error': 'Error',
  };

  return (
    <Head>
      <title>
        {(routeTitles?.[routeName] || '') !== ''
          ? `${routeTitles?.[routeName]} | ${sitename}` : sitename}
      </title>
    </Head>
  );
};

export default HeadConfig;
