import React, { useRef, useState } from 'react';
import { Button, Typography, IconButton } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import IdleTimer from 'react-idle-timer';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import Countdown from 'react-countdown';
import { useCartDispatcher } from 'contexts/CartContext';

const IdleChecker = () => {
  const dispatchCart = useCartDispatcher();
  const idleTimerRef = useRef(null);
  const sessionTimeOutRef = useRef(null);
  const [open, setOpen] = useState(false);

  const LogoutUser = () => {
    // clear redux store
    dispatchCart({ type: 'clear' });
    // force logout user
    window.location = '/api/auth/logout';
  };

  // Modal Functions
  const handleOpen = () => {
    setOpen(true);
  };
  const stayActive = () => {
    setOpen(false);
    clearTimeout(sessionTimeOutRef.current);
  };
  // END MODAL functions
  const onIdle = () => {
    handleOpen();
    sessionTimeOutRef.current = setTimeout(LogoutUser, 60000); // Log out After 1 min once modal is displayed.
  };

  // Renderer callback with condition
  const renderer = ({ seconds }) => <span>{seconds}</span>;

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        element={document}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 29 * 60} // 29-min alert prompt. Will logout at 30min
      />
      <Modal
        open={open}
        onClose={stayActive}
        aria-labelledby='modal-title'
        className='modal-wrapper'
      >
        <Fade in={open}>
          <div className='modal-container'>
            <div className='modal-header'>
              <Typography variant='h2' id='modal-title'>{'Still There?'}</Typography>
              <IconButton className='close' aria-label='close' size='medium' onClick={stayActive}>
                <CloseIcon />
              </IconButton>
            </div>
            <div className='modal-body center'>
              <Typography variant='body1'>
                {'The session will be expiring soon. You will be automatically signed out in '}
                <strong>
                  <Countdown date={Date.now() + 59000} renderer={renderer} />
                  {' seconds'}
                </strong>
              </Typography>
              <Button id='idle-signout-button' type='submit' variant='contained' color='primary' onClick={LogoutUser}>
                {'Sign Out'}
              </Button>
              <Button id='idle-stay-active-button' type='submit' variant='contained' color='secondary' onClick={stayActive}>
                {'Stay Active'}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default IdleChecker;
