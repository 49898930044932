import { Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const ErrorMessage = () => (
  <div className='content-card center error-message'>
    <WarningIcon />
    <Typography variant='h1'>{'Something Went Wrong'}</Typography>
    <Typography variant='body1' className='error'>
      {'Seems like there was an issue. Sorry for the inconvenience. Please refresh your screen and try again. If this issue persists contact our customer service line at (909) 204-5266'}
    </Typography>
  </div>
);

export default ErrorMessage;
