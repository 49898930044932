import { Button, ListItem, ListItemText } from '@material-ui/core';

import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { useContent, useMjrApiUser } from 'utils/swrCalls';

const NavLinks = () => {
  const { data: user } = useMjrApiUser();
  const { data: content } = useContent();
  const route = useRouter()?.pathname;
  const links = [
    {
      name: 'Home', external: true, path: 'https://myjewelryrepair.com/', id: 'home-link',
    },
    {
      name: 'Help', external: true, path: 'https://myjewelryrepair.com/contact/', id: 'help-link',
    },
    {
      name: 'New Order', external: false, path: '/create-order', id: 'new-order-link',
    },
    {
      name: 'My Orders', external: false, path: '/my-orders', id: 'my-orders-link',
    },
    {
      name: 'My Account', external: false, path: '/my-account', id: 'my-account-link',
    },
  ];
  if (content?.content?.globalHeader?.altNavTitles) {
    links[2].name = content?.content?.globalHeader?.altNavTitles?.newOrderLink;
    links[3].name = content?.content?.globalHeader?.altNavTitles?.myOrdersLink;
  }
  if (user?.customerId) {
    return (
      <>
        {links.map((link, index) => {
          if (link.external) {
            return (
              <ListItem className={route === link.path ? 'active' : ''} key={index}>
                <a id={link.id} href={link.path}>
                  <Button variant='text'>
                    <ListItemText primary={link.name} />
                  </Button>
                </a>
              </ListItem>
            );
          }
          return (
            <Link href={link.path} key={link.id}>
              <ListItem className={route === link.path ? 'active' : ''}>
                <Button id={link.id} variant='text'>
                  <ListItemText primary={link.name} />
                </Button>
              </ListItem>
            </Link>
          );
        })}
      </>
    );
  }
  return null;
};

export default NavLinks;
