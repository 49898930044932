import React, { useState } from 'react';
import { Drawer, Button } from '@material-ui/core';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CartButton from 'components/Cart/CartButton';
import Cart from 'components/Cart/Cart';
import { useCartGetter } from 'contexts/CartContext';

const CartDrawer = () => {
  const cart = useCartGetter();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const route = useRouter()?.pathname;
  const anchor = 'right';

  const handleKeyDown = (event) => {
    if (event.key !== 'Tab' && event.key !== 'Shift') {
      setDrawerOpen(false);
    }
  };

  const handleDrawerClick = (event) => {
    if (!event.target.classList.contains('remove-item-button') || (cart.items.length - 1) === 0) {
      setDrawerOpen(false);
    }
  };

  return (
    <div>
      {(route !== '/order-summary' && cart.items?.length > 0) && (
      <div className='cart-button'>
        <CartButton id='cart-open-button' clickHandler={() => setDrawerOpen(true)} />
      </div>
      )}
      <Drawer anchor={anchor} open={drawerOpen} onClose={() => setDrawerOpen(false)} onKeyDown={handleKeyDown}>
        <div
          className='side-cart-drawer'
          role='presentation'
          onClick={handleDrawerClick}
        >
          <div className='side-cart-wrapper'>
            <Cart mini />
          </div>
          <Link href='/order-summary'>
            <Button id='checkout-cart-button' variant='contained' color='secondary'>{'Checkout'}</Button>
          </Link>
        </div>
      </Drawer>
    </div>
  );
};

export default CartDrawer;
