import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import { logError } from 'utils/errorLogger';

const defaultInitialOrderState = {};

const orderReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case 'set':
      return { ...action.payload };
    default:
      logError(new Error(JSON.stringify(state)), 'OrderProvider called action that does not exist');
      throw new Error(JSON.stringify(state));
  }
};

const OrderGetterContext = createContext();
const OrderDispatcherContext = createContext();

export const OrderProvider = ({ children }) => {
  let initialValueOfOrder = defaultInitialOrderState;
  if (typeof window !== 'undefined') {
    initialValueOfOrder = JSON.parse(window?.localStorage.getItem('OrderContext')) || initialValueOfOrder;
  }

  const [state, dispatch] = useReducer(orderReducer, { ...initialValueOfOrder });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.localStorage.setItem('OrderContext', JSON.stringify(state));
    }
  }, [state]);

  return (
    <OrderDispatcherContext.Provider value={dispatch}>
      <OrderGetterContext.Provider value={state}>
        {children}
      </OrderGetterContext.Provider>
    </OrderDispatcherContext.Provider>
  );
};

export const useOrderGetter = () => {
  const context = useContext(OrderGetterContext);
  if (context === undefined) {
    throw new Error('useOrderGetter must be used within a OrderProvider.');
  }
  return context;
};

export const useOrderDispatcher = () => {
  const context = useContext(OrderDispatcherContext);
  if (context === undefined) {
    throw new Error('useOrderDispatcher must be used within a OrderProvider');
  }
  return context;
};
